import React from 'react';
import { FaTimes, FaClock, FaUsers, FaCalendarAlt, FaMapMarkerAlt, FaCheck, FaHiking, FaCamera, FaBinoculars, FaTree, FaMountain, FaCar, FaSwimmer, FaUtensils, FaWater, FaTicketAlt, FaUser, FaBed, FaShuttleVan, FaGlassCheers, FaUmbrellaBeach, FaPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './PackageDetailsModal.css';

const PackageDetailsModal = ({ package: pkg, onClose }) => {
  const navigate = useNavigate();
  
  if (!pkg) return null;

  // Helper function to format meals object for display
  const formatMeals = (meals) => {
    if (!meals) return 'Not specified';
    if (typeof meals === 'string') return meals;
    
    // If meals is an object with keys like breakfast, lunch, dinner
    if (typeof meals === 'object') {
      return Object.entries(meals).map(([meal, detail]) => (
        `${meal.charAt(0).toUpperCase() + meal.slice(1)}: ${detail}`
      )).join(', ');
    }
    
    return 'Not specified';
  };

  // Helper function to clean activity text by removing time indicators
  const cleanActivityText = (activity) => {
    if (!activity) return '';
    
    // Remove time formats like "09:00 - " or "09:00-11:00: "
    let cleaned = activity.replace(/^\d{1,2}[:\.]\d{2}\s*[-–—]\s*(\d{1,2}[:\.]\d{2}\s*)?[:\s]*/i, '');
    
    // Remove time periods like "Morning: " or "Afternoon - "
    cleaned = cleaned.replace(/^(morning|afternoon|evening|night)(\s*[-:]\s*)/i, '');
    
    // Capitalize first letter if it was lowercased after removing prefix
    return cleaned.charAt(0).toUpperCase() + cleaned.slice(1);
  };

  // Helper function to get appropriate icon for activity types
  const getActivityIcon = (activity) => {
    const text = activity.toLowerCase();
    
    if (text.includes('hike') || text.includes('walk') || text.includes('trek')) 
      return <FaHiking className="activity-icon" />;
    if (text.includes('photo') || text.includes('camera')) 
      return <FaCamera className="activity-icon" />;
    if (text.includes('game drive') || text.includes('safari') || text.includes('wildlife')) 
      return <FaBinoculars className="activity-icon" />;
    if (text.includes('forest') || text.includes('nature')) 
      return <FaTree className="activity-icon" />;
    if (text.includes('mountain') || text.includes('hill')) 
      return <FaMountain className="activity-icon" />;
    if (text.includes('drive') || text.includes('transfer') || text.includes('journey')) 
      return <FaCar className="activity-icon" />;
    if (text.includes('swim') || text.includes('beach')) 
      return <FaSwimmer className="activity-icon" />;
    if (text.includes('dinner') || text.includes('lunch') || text.includes('breakfast') || text.includes('meal')) 
      return <FaUtensils className="activity-icon" />;
    if (text.includes('lake') || text.includes('river') || text.includes('waterfall') || text.includes('boat')) 
      return <FaWater className="activity-icon" />;
    
    // Default icon
    return <FaCheck className="activity-icon" />;
  };

  // Helper function to get appropriate icon for included items
  const getIncludedIcon = (item) => {
    const text = item.toLowerCase();
    
    if (text.includes('entrance') || text.includes('fee') || text.includes('permit')) 
      return <FaTicketAlt className="included-icon" />;
    if (text.includes('guide') || text.includes('ranger') || text.includes('staff')) 
      return <FaUser className="included-icon" />;
    if (text.includes('accommodation') || text.includes('lodge') || text.includes('camp')) 
      return <FaBed className="included-icon" />;
    if (text.includes('transport') || text.includes('transfer') || text.includes('pickup')) 
      return <FaShuttleVan className="included-icon" />;
    if (text.includes('meal') || text.includes('food') || text.includes('drink') || text.includes('breakfast') || text.includes('lunch') || text.includes('dinner')) 
      return <FaUtensils className="included-icon" />;
    if (text.includes('activity') || text.includes('game drive')) 
      return <FaBinoculars className="included-icon" />;
    if (text.includes('water') || text.includes('refreshment')) 
      return <FaGlassCheers className="included-icon" />;
    if (text.includes('beach') || text.includes('leisure')) 
      return <FaUmbrellaBeach className="included-icon" />;
    if (text.includes('flight') || text.includes('air') || text.includes('transfer')) 
      return <FaPlane className="included-icon" />;
    
    // Default icon
    return <FaCheck className="included-icon" />;
  };

  const handleBookNow = () => {
    // Extract numeric price value and determine budget range
    const priceStr = pkg.price.replace(/[^0-9]/g, '');
    const price = parseInt(priceStr);
    
    let budgetRange;
    if (price <= 1000) budgetRange = "Under $1,000";
    else if (price <= 2000) budgetRange = "$1,000 - $2,000";
    else if (price <= 3000) budgetRange = "$2,000 - $3,000";
    else if (price <= 5000) budgetRange = "$3,000 - $5,000";
    else budgetRange = "Above $5,000";

    // Navigate to enquiry form with pre-filled data
    navigate('/enquiry', {
      state: {
        tripName: pkg.title,
        budgetRange: budgetRange
      }
    });
    onClose();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="logo-circle">
          <img 
            src="/images/logo.png" 
            alt="Tanzania Camping Logo" 
          />
        </div>

        <div className="modal-header">
          <div className="modal-image">
            <img src={pkg.image} alt={pkg.title} />
            <div className="modal-categories">
              {pkg.categories.map((cat, index) => (
                <span key={index} className="modal-category">{cat}</span>
              ))}
            </div>
          </div>
          <div className="modal-title">
            <h2>{pkg.title}</h2>
            <div className="modal-meta">
              <span><FaClock /> {pkg.duration}</span>
              <span><FaUsers /> {pkg.groupSize}</span>
              <span><FaCalendarAlt /> Best Time: {pkg.bestTime}</span>
              {pkg.parkId && (
                <span><FaMapMarkerAlt /> {pkg.parkId.charAt(0).toUpperCase() + pkg.parkId.slice(1)}</span>
              )}
            </div>
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-description">
            <h3>Overview</h3>
            <p>{pkg.description}</p>
          </div>

          <div className="modal-highlights">
            <h3>Highlights</h3>
            <ul>
              {pkg.highlights.map((highlight, index) => (
                <li key={index}><FaCheck /> {highlight}</li>
              ))}
            </ul>
          </div>

          <div className="modal-itinerary">
            <h3>Detailed Itinerary</h3>
            <div className="itinerary-container">
              {pkg.itinerary.map((day, index) => (
                <div key={index} className="itinerary-day">
                  <div className="itinerary-day-header">
                    <h4>Day {index + 1}: {day.title}</h4>
                  </div>
                  
                  <div className="itinerary-day-content">
                    {/* Vertically arranged sections, each in its own row */}
                    <div className="itinerary-row">
                      <div className="itinerary-col full-width">
                        <h5>Description</h5>
                        <p>{day.description}</p>
                      </div>
                    </div>
                    
                    <div className="itinerary-row">
                      <div className="itinerary-col full-width">
                        <h5>Activities</h5>
                        <div className="activities-grid">
                          {day.activities.map((activity, actIndex) => (
                            <div key={actIndex} className="activity-item">
                              {getActivityIcon(activity)}
                              <span>{cleanActivityText(activity)}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    
                    <div className="itinerary-row">
                      <div className="itinerary-col full-width">
                        <h5>Meals</h5>
                        <p>{formatMeals(day.meals)}</p>
                      </div>
                    </div>
                    
                    <div className="itinerary-row">
                      <div className="itinerary-col full-width">
                        <h5>Accommodation</h5>
                        <p>{day.accommodation || 'Not specified'}</p>
                      </div>
                    </div>
                    
                    {day.included && (
                      <div className="itinerary-row">
                        <div className="itinerary-col full-width">
                          <h5>Included</h5>
                          <div className="included-grid">
                            {Array.isArray(day.included) ? 
                              day.included.map((item, itemIndex) => (
                                <div key={itemIndex} className="included-item">
                                  {getIncludedIcon(item)}
                                  <span>{item}</span>
                                </div>
                              )) : 
                              <div className="included-item">
                                {getIncludedIcon(day.included)}
                                <span>{day.included}</span>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <div className="price-section">
            <span className="price-label">Price per person</span>
            <span className="price-amount">{pkg.price}</span>
          </div>
          <button className="book-now-btn" onClick={handleBookNow}>Book Now</button>
        </div>
      </div>
    </div>
  );
};

export default PackageDetailsModal; 