import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaCompass, FaCampground, FaUsers, FaShieldAlt, FaClock, FaArrowRight, FaSmile, FaAward, FaChevronLeft, FaChevronRight, FaStar, FaCheck } from 'react-icons/fa';
import './Home.css';
import SEO from '../components/SEO';
import PartnersCarousel from '../components/PartnersCarousel';

const Home = () => {
  const [selectedPackage, setSelectedPackage] = useState(null);

  const scrollAdventures = (direction) => {
    const container = document.querySelector('.adventure-grid');
    const scrollAmount = direction === 'left' ? -400 : 400;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const homeSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "speakable": {
      "@type": "SpeakableSpecification",
      "cssSelector": ["headline", "summary"]
    },
    "headline": "Tanzania Tourism: Premier Camping and Self Drive Safari Adventures",
    "description": "Tanzania's leading tourism provider for camping and self-drive safari experiences. Discover Tanzania's magnificent wildlife and landscapes with our expert guides.",
    "mainEntity": {
      "@type": "TravelAgency",
      "name": "Tanzania Camping and Self Drive",
      "description": "Tanzania's top-rated tourism and safari operator. We specialize in customized camping safaris and self-drive adventures in Tanzania's most iconic wildlife destinations.",
      "areaServed": "Tanzania",
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Tanzania Tourism Experiences",
        "itemListElement": [
          {
            "@type": "Offer",
            "name": "Private Camping Safaris",
            "description": "Exclusive Tanzania tourism experiences with private camping in prime wildlife locations"
          },
          {
            "@type": "Offer", 
            "name": "Self-Drive Tanzania Adventures",
            "description": "Independent Tanzania tourism exploration with premium 4x4 vehicles and expert support"
          },
          {
            "@type": "Offer",
            "name": "Tanzania Wildlife Photography Tours",  
            "description": "Specialized photography safaris led by expert guides in Tanzania's most scenic locations"
          },
          {
            "@type": "Offer",
            "name": "Tanzania Cultural Tourism Packages",  
            "description": "Authentic cultural experiences with local Maasai communities and other Tanzanian tribes"
          },
          {
            "@type": "Offer",
            "name": "Tanzania Honeymoon Safaris",  
            "description": "Romantic Tanzania tourism packages combining luxury accommodations with wildlife adventures"
          }
        ]
      }
    },
    "keywords": "Tanzania tourism, Tanzania safari, Tanzania vacation, Tanzania travel, Tanzania adventure, Tanzania wildlife, Tanzania camping, Tanzania self-drive, Serengeti tours, Ngorongoro safaris"
  };

  return (
    <>
      <SEO 
        title="Tanzania's Premier Safari Experience | Camping and Self Drive Adventures"
        description="Experience the ultimate Tanzania tourism adventure with the country's leading camping and self-drive safari operator. Discover Tanzania's world-famous wildlife parks including Serengeti, Ngorongoro, and Kilimanjaro. Personalized itineraries, expert local guides, and unforgettable wildlife encounters on your Tanzania vacation."
        keywords="Tanzania tourism, Tanzania safari, Tanzania camping safari, self drive Tanzania, Serengeti safari, Ngorongoro tours, Kilimanjaro climbing, Tanzania vacation, Tanzania holiday packages, Tanzania wildlife tours, best safari company Tanzania, Tanzania travel deals, Tanzania adventure holidays, Tanzania tour operator, Tanzania safari itineraries, Tanzania safari lodges, Tanzania wildlife photography, Tanzania national parks, Tanzania guided tours, Tanzania family safaris"
        schema={homeSchema}
      />
      <div className="home">
        <section className="hero">
          <div className="hero-video-container">
            <video 
              className="hero-video"
              autoPlay 
              loop 
              muted 
              playsInline
            >
              <source src="/videos/wildlife-hero.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="hero-content">
            <h1>Tanzania Camping and Self Drive</h1>
            <p>Discover the magic of camping safaris and self ddriving in Africa's most beautiful landscapes</p>
            <Link to="/trips" className="cta-button">Explore Tours</Link>
          </div>
        </section>

        <section className="featured-tours">
          <h2 className="section-title">Popular Safari Packages</h2>
          <div className="tour-grid">
            {popularTours.map((pkg, index) => (
              <div key={index} className="package-card">
                <div className="package-image">
                  <img src={pkg.image} alt={pkg.title} />
                  <div className="package-duration">{pkg.duration}</div>
                  {pkg.featured && <div className="package-featured">Featured</div>}
                </div>
                <div className="package-content">
                  <h3>{pkg.title}</h3>
                  <p>{pkg.description}</p>
                  <div className="package-meta">
                    <div className="package-price">
                      <span>From</span>
                      <strong>${pkg.price}</strong>
                    </div>
                    <button 
                      onClick={() => setSelectedPackage(pkg)} 
                      className="view-details-btn"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="why-choose-us">
          <h2 className="section-title">Why Choose Us</h2>
          <div className="features">
            {features.map((feature) => (
              <div key={feature.id} className="feature">
                {feature.icon}
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="testimonials">
          <h2 className="section-title">What Our Clients Say</h2>
          <div className="testimonial-grid">
            {testimonials.map((testimonial) => (
              <div key={testimonial.id} className="testimonial-card">
                <div className="testimonial-content">
                  <p>"{testimonial.text}"</p>
                  <div className="testimonial-author">
                    <img src={testimonial.avatar} alt={testimonial.name} />
                    <div>
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.location}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="adventure-types">
          <h2 className="section-title">Adventure Categories</h2>
          <button className="scroll-button scroll-left" onClick={() => scrollAdventures('left')}>
            <FaChevronLeft />
          </button>
          <div className="adventure-grid">
            {adventureTypes.map((type) => (
              <div key={type.id} className="adventure-card">
                <img src={type.image} alt={type.title} />
                <div className="adventure-content">
                  <h3>{type.title}</h3>
                  <p>{type.description}</p>
                  <Link to={type.link} className="adventure-link">
                    Explore More <FaArrowRight />
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <button className="scroll-button scroll-right" onClick={() => scrollAdventures('right')}>
            <FaChevronRight />
          </button>
        </section>

        <section className="stats-section">
          <div className="stats-container">
            {stats.map((stat) => (
              <div key={stat.id} className="stat-item">
                <h3>{stat.number}</h3>
                <p>{stat.label}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="gallery-section">
          <h2 className="section-title">Adventure Gallery</h2>
          <div className="gallery-grid">
            {galleryImages.map((image) => (
              <div key={image.id} className="gallery-item">
                <img src={image.url} alt={image.title} />
                <div className="gallery-overlay">
                  <h4>{image.title}</h4>
                  <p>{image.location}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="google-reviews-section">
          <h2 className="section-title">Words from Google Reviews</h2>
          <div className="reviews-container">
            <div 
              className="elfsight-app-83a82b91-c1f2-471f-89cf-d1d12175f139" 
              data-elfsight-app-lazy
            ></div>
          </div>
        </section>

        <section className="home-partners-section">
          <div className="container">
            <PartnersCarousel />
          </div>
        </section>

        <section className="cta-section">
          <div className="cta-content">
            <h2>Ready for Your Adventure?</h2>
            <p>Book your unforgettable Tanzania camping experience today</p>
            <div className="cta-buttons">
              <Link to="/contact-us" className="cta-button primary">Contact Us</Link>
              <Link to="/trips" className="cta-button secondary">View All Tours</Link>
            </div>
          </div>
        </section>
      </div>

      {selectedPackage && (
        <div className="package-modal-overlay" onClick={() => setSelectedPackage(null)}>
          <div className="package-modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setSelectedPackage(null)}>×</button>
            
            <div className="modal-image">
              <img src={selectedPackage.image} alt={selectedPackage.title} />
              <div className="modal-badges">
                <span className="package-duration-badge">
                  <FaClock /> {selectedPackage.duration}
                </span>
                {selectedPackage.featured && (
                  <span className="package-featured-badge">
                    <FaAward /> Featured
                  </span>
                )}
              </div>
            </div>
            
            <div className="modal-content">
              <h2>{selectedPackage.title}</h2>
              
              <div className="modal-description">
                <h3><FaCompass /> Description</h3>
                <p>{selectedPackage.description}</p>
              </div>
              
              <div className="modal-highlights">
                <h3><FaStar /> Package Highlights</h3>
                <ul>
                  {selectedPackage.highlights.map((highlight, i) => (
                    <li key={i}>{highlight}</li>
                  ))}
                </ul>
              </div>
              
              <div className="modal-includes">
                <h3><FaCheck /> What's Included</h3>
                <ul>
                  {selectedPackage.includes.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
              
              <div className="modal-footer">
                <div className="modal-price">
                  <span>From ${selectedPackage.price}</span>
                  <span className="price-per">per person</span>
                </div>
                <div className="modal-buttons">
                  <Link 
                    to={selectedPackage.parkLink || "/parks"} 
                    className="explore-btn"
                  >
                    <FaCompass /> Explore
                  </Link>
                  <Link 
                    to="/enquiry" 
                    state={{ 
                      tripName: selectedPackage.title,
                      tripDetails: `Package: ${selectedPackage.title}
Duration: ${selectedPackage.duration}
Price: $${selectedPackage.price} per person
Description: ${selectedPackage.description}
Highlights: ${selectedPackage.highlights.join(', ')}
Includes: ${selectedPackage.includes.join(', ')}`
                    }}
                    className="book-now-btn"
                  >
                    Book Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const popularTours = [
  {
    id: 1,
    title: "Serengeti Migration Safari",
    description: "Witness the great wildebeest migration in luxury camping style.",
    image: "https://img.freepik.com/premium-photo/zebras-wildebeest-standing-field_1048944-8233060.jpg?semt=ais_hybrid",
    duration: "6 Days",
    price: "1,299",
    parkLink: "/parks-reserves/northern/serengeti",
    featured: true,
    highlights: [
      "Witness the Great Migration",
      "Luxury camping in the heart of Serengeti",
      "Expert wildlife guides",
      "Close encounters with the Big Five"
    ],
    includes: [
      "All meals and accommodations",
      "Professional safari guide",
      "Game drives in 4x4 vehicle",
      "Park entrance fees",
      "Airport transfers"
    ]
  },
  {
    id: 2,
    title: "Ngorongoro Crater Adventure",
    description: "Explore the world's largest intact volcanic caldera.",
    image: "https://images.unsplash.com/photo-1516426122078-c23e76319801?w=800",
    duration: "4 Days",
    price: "999",
    parkLink: "/parks-reserves/northern/ngorongoro",
    featured: false,
    highlights: [
      "Full day in Ngorongoro Crater",
      "Highest concentration of wildlife in Africa",
      "Chance to see endangered black rhino",
      "Stunning crater views"
    ],
    includes: [
      "Accommodation in luxury lodges",
      "All meals included",
      "Professional guide",
      "4x4 safari vehicle",
      "Conservation fees"
    ]
  },
  {
    id: 3,
    title: "Kilimanjaro Base Camp",
    description: "Experience the majesty of Africa's highest peak.",
    image: "https://img.freepik.com/free-photo/beautiful-scenery-yellow-tents-kilimanjaro-national-park_181624-36749.jpg?semt=ais_hybrid",
    duration: "5 Days",
    price: "1,499",
    parkLink: "/parks-reserves/northern/kilimanjaro",
    featured: true,
    highlights: [
      "Trek to Kilimanjaro base camp",
      "Stunning mountain views",
      "Diverse ecosystems and wildlife",
      "Cultural interactions with local guides"
    ],
    includes: [
      "Camping equipment and setup",
      "Experienced mountain guides",
      "All meals during trek",
      "Park and camping fees",
      "Safety equipment"
    ]
  }
];

const features = [
  {
    id: 1,
    icon: <FaCompass className="feature-icon" />,
    title: "Expert Local Guides",
    description: "Our experienced guides bring deep knowledge and passion to every safari."
  },
  {
    id: 2,
    icon: <FaCampground className="feature-icon" />,
    title: "Luxury Camping",
    description: "Premium camping facilities with all modern amenities for your comfort."
  },
  {
    id: 3,
    icon: <FaUsers className="feature-icon" />,
    title: "Small Groups",
    description: "Intimate group sizes ensure personalized attention and better wildlife viewing."
  },
  {
    id: 4,
    icon: <FaShieldAlt className="feature-icon" />,
    title: "Safety First",
    description: "Your safety is our priority with well-maintained equipment and protocols."
  }
];

const testimonials = [
  {
    id: 1,
    text: "An incredible experience! The camping facilities were excellent and the wildlife viewing was beyond our expectations.",
    name: "John Smith",
    location: "United States",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg"
  },
  {
    id: 2,
    text: "The guides were knowledgeable and passionate. We saw all the Big Five and more!",
    name: "Emma Wilson",
    location: "United Kingdom",
    avatar: "https://randomuser.me/api/portraits/women/2.jpg"
  },
  {
    id: 3,
    text: "A perfect blend of adventure and comfort. The starlit dinners were magical!",
    name: "Michael Chen",
    location: "Canada",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg"
  }
];

const adventureTypes = [
  {
    id: 1,
    title: "Wildlife Safari",
    description: "Experience close encounters with Africa's majestic wildlife.",
    image: "https://images.unsplash.com/photo-1549366021-9f761d450615?w=800",
    link: "/trips/wildlife-safari"
  },
  {
    id: 2,
    title: "Cultural Immersion",
    description: "Discover the rich traditions of Tanzania's diverse tribes.",
    image: "https://img.freepik.com/premium-photo/africa-tanzania-march-04-2016-european-tourists-interact-with-locals-africa-village-masai-tribe_135785-763.jpg?semt=ais_hybrid",
    link: "/trips/cultural-tours"
  },
  {
    id: 3,
    title: "Mountain Trekking",
    description: "Conquer the peaks of Kilimanjaro and other mountains.",
    image: "https://images.unsplash.com/photo-1521150932951-303a95503ed3?w=800",
    link: "/trips/mountain-climbing"
  },
  {
    id: 4,
    title: "Beach Holidays",
    description: "Relax on Zanzibar's pristine beaches and crystal-clear waters.",
    image: "https://img.freepik.com/free-photo/beautiful-tropical-beach-sea-with-coconut-palm-tree_74190-7488.jpg",
    link: "/trips/beach-holidays"
  },
  
  {
    id: 6,
    title: "Day Trips",
    description: "Explore nearby attractions and hidden gems in a day.",
    image: "/images/Photography.jpeg",
    link: "/trips/day-trips"
  }
];

const stats = [
  {
    id: 1,
    number: "2000+",
    label: "Happy Clients"
  },
  {
    id: 2,
    number: "150+",
    label: "Destinations"
  },
  {
    id: 3,
    number: "70+",
    label: "Camping Sites"
  },
  {
    id: 4,
    number: "25+",
    label: "Years Experience"
  }
];

const galleryImages = [
  {
    id: 1,
    url: "https://img.freepik.com/free-photo/crossroad-car-safari-scene_23-2151822336.jpg",
    title: "Serengeti Sunset",
    location: "Serengeti National Park"
  },
  {
    id: 2,
    url: "https://images.unsplash.com/photo-1547471080-7cc2caa01a7e?w=800",
    title: "Maasai Village",
    location: "Ngorongoro Conservation Area"
  },
  {
    id: 3,
    url: "https://images.unsplash.com/photo-1516426122078-c23e76319801?w=800",
    title: "Safari Experience",
    location: "Serengeti Plains"
  },
  {
    id: 4,
    url: "https://images.unsplash.com/photo-1549366021-9f761d450615?w=800",
    title: "Elephant Herd",
    location: "Tarangire National Park"
  },
  {
    id: 5,
    url: "https://images.unsplash.com/photo-1515205244153-fce4e5d8bc49?w=800",
    title: "Crater View",
    location: "Ngorongoro Crater"
  },
  {
    id: 6,
    url: "https://images.unsplash.com/photo-1521150932951-303a95503ed3?w=800",
    title: "Mountain Peak",
    location: "Mount Kilimanjaro"
  }
];

export default Home; 