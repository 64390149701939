import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({ title, description, canonicalUrl, image, keywords, schema }) {
  const siteTitle = "Tanzania Camping and Self Drive";
  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const defaultDescription = "Tanzania's leading camping and self-drive safari provider. Experience luxury camping in Serengeti, Ngorongoro, and Kilimanjaro.";
  const metaDescription = description || defaultDescription;
  const defaultKeywords = "Tanzania Camping and Self Drive, Tanzania tourism, Tanzania safari, Tanzania wildlife tours, Self Drive Tanzania, Serengeti safari, Ngorongoro crater tours, Kilimanjaro climbing, Tanzania tour packages, Tanzania vacation, safari lodging Tanzania, Tanzania travel agency, best wildlife tours Tanzania, Tanzania adventure holidays, Tanzania photography safari, Tanzania camping, Tanzania luxury travel, Tanzania eco tourism, Tanzania private safari, Tanzania safari costs, Tanzania accommodation";
  const metaKeywords = keywords || defaultKeywords;
  
  return (
    <Helmet>
      <title>{fullTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={metaDescription} />
      {image && <meta property="og:image" content={image} />}
      
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {image && <meta name="twitter:image" content={image} />}
      
      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Structured Data */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
    </Helmet>
  );
}

export default SEO; 