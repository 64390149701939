import React, { useEffect, useState } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaWhatsapp, FaFacebookF, FaInstagram, FaYoutube, FaClock, FaPlane, FaHotel, FaQuestionCircle, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import './ContactUs.css';
import { env } from 'process';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 3; // You can adjust this based on how many reviews you want to show

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      // Send message to admin
      const adminResult = await emailjs.send(
        "service_0jhfyjd",
        "template_t59wcqd",
        {
          from_name: formData.name,
          from_email: formData.email,
          subject: formData.subject,
          message: formData.message,
          to_email: 'pearlktech@gmail.com'
        },
        "qji5dApARjCmYSNUq"
      );

      // Send auto-reply to customer
      if (adminResult.text === 'OK') {
        await emailjs.send(
          "service_0jhfyjd",
          "template_10vndha", // You'll create this template
          {
            from_name: formData.name,
            subject: formData.subject,
            to_email: formData.email
          },
          "qji5dApARjCmYSNUq"
        );

        setStatus('success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      }
    } catch (error) {
      console.error('Email error:', error);
      setStatus('error');
    }

    setTimeout(() => setStatus(''), 5000);
  };

  useEffect(() => {
    // Reload Facebook plugins after component mounts
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);

  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    
    // Append to document
    document.body.appendChild(script);
    
    // Clean up
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === totalSlides - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? totalSlides - 1 : prev - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="contact-page">
      <section className="contact-hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Get in Touch</h1>
          <p>Let us help you plan your perfect African safari adventure</p>
        </div>
      </section>

      <div className="contact-container">
        <div className="contact-info-wrapper">
          <div className="contact-form-container">
            <div className="form-header">
              <h2>Send us a Message</h2>
              <p>We'll get back to you as soon as possible</p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label>Your Name</label>
                  <input 
                    type="text" 
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name" 
                    required 
                  />
                </div>
                <div className="form-group">
                  <label>Your Email</label>
                  <input 
                    type="email" 
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email" 
                    required 
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Subject</label>
                <input 
                  type="text" 
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="What is this about?" 
                  required 
                />
              </div>
              <div className="form-group">
                <label>Your Message</label>
                <textarea 
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Tell us about your travel plans..." 
                  rows="5" 
                  required
                ></textarea>
              </div>
              <button 
                type="submit" 
                className="submit-btn"
                disabled={status === 'sending'}
              >
                {status === 'sending' ? 'Sending...' : 'Send Message'}
                <span className="btn-icon">→</span>
              </button>
              {status === 'success' && (
                <div className="form-status success">
                  Message sent successfully! We'll get back to you soon.
                </div>
              )}
              {status === 'error' && (
                <div className="form-status error">
                  Failed to send message. Please try again later.
                </div>
              )}
            </form>
          </div>

          <div className="contact-card">
            <div className="card-header">
              <h2>Tanzania Central Reservations Office</h2>
              <p>Your gateway to an unforgettable African safari experience</p>
            </div>
            <div className="contact-details">
              <div className="contact-item">
                <FaMapMarkerAlt />
                <div>
                  <h3>Address</h3>
                  <p>P.o.Box 13498,</p>
                  <p>Levolosi, Plot.18F Arusha Tanzania (EA)</p>
                </div>
              </div>

              <div className="contact-item">
                <FaEnvelope />
                <div>
                  <h3>Email</h3>
                  <p>Meena Tegemea: info@tanzaniacampingsafaris.com</p>
                </div>
              </div>

              <div className="contact-item">
                <FaPhone />
                <div>
                  <h3>Phone</h3>
                  <p>Mobile: +255 754 303 873</p>
                </div>
              </div>

              <div className="contact-item">
                <FaWhatsapp />
                <div>
                  <h3>WhatsApp</h3>
                  <p>+255 622 403 223 (Tanzania)</p>
                </div>
              </div>

              <div className="contact-item">
                <FaClock />
                <div>
                  <h3>Business Hours</h3>
                  <p>Monday - Friday: 8:00 AM - 6:00 PM</p>
                  <p>Saturday: 9:00 AM - 3:00 PM</p>
                  <p>Sunday: Closed</p>
                </div>
              </div>
            </div>

            <div className="license-info">
              <p>Travel Licence Class "A"</p>
            </div>
          </div>
        </div>

        <section className="quick-services">
          <h2>Our Services</h2>
          <div className="services-grid">
            {quickServices.map((service) => (
              <div key={service.id} className="service-item">
                <div className="service-icon">{service.icon}</div>
                <div className="service-content">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section className="social-connect">
          <h2>Connect With Us</h2>
          <div className="social-grid">
            <div className="social-card facebook">
              <div className="social-header">
                <FaFacebookF />
                <h3>Facebook</h3>
              </div>
              <div className="social-content">
                <div 
                  className="fb-page" 
                  data-href="https://www.facebook.com/TanzaniaCamping"
                  data-tabs="timeline"
                  data-width="500"
                  data-height="670"
                  data-small-header="false"
                  data-adapt-container-width="true"
                  data-hide-cover="false"
                  data-show-facepile="true">
                  <blockquote 
                    cite="https://www.facebook.com/TanzaniaCamping" 
                    className="fb-xfbml-parse-ignore">
                    <a href="https://www.facebook.com/TanzaniaCamping">
                      Tanzania Camping Safaris
                    </a>
                  </blockquote>
                </div>
              </div>
            </div>

            <div className="social-card instagram">
              <div className="social-header">
                <FaInstagram />
                <h3>Instagram</h3>
              </div>
              <div className="instagram-feed">
                <iframe
                  src={`https://www.instagram.com/gofansafaris/embed`}
                  // width="100"
                  height="470"
                  data-width="500"
                  data-height="670"
                  frameBorder="0"
                  scrolling="yes"
                  allowTransparency="true"
                ></iframe>
              </div>
            </div>

            <div className="social-card youtube">
              <div className="social-header">
                <FaYoutube />
                <h3>YouTube</h3>
              </div>
              <div className="youtube-feed">
                <iframe
                  width="100%"
                  height="470px"
                  src="https://www.youtube.com/embed/lq2g2MggY3E?si=4obCql8gK5BHnOYb"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="reviews-carousel-section">
          <div className="container">
            <h2 className="section-title">Words from Google Reviews</h2>
            <p className="section-subtitle">See what our clients are saying about their experiences with us</p>
            
            <div className="reviews-carousel-container">
              <div className="reviews-carousel-inner">
                <div 
                  className="elfsight-app-83a82b91-c1f2-471f-89cf-d1d12175f139" 
                  data-elfsight-app-lazy
                ></div>
              </div>
              
              <div className="carousel-controls">
                <button className="carousel-control prev" onClick={prevSlide}>
                  <FaChevronLeft />
                </button>
                <button className="carousel-control next" onClick={nextSlide}>
                  <FaChevronRight />
                </button>
              </div>
              
              <div className="carousel-dots">
                {[...Array(totalSlides)].map((_, index) => (
                  <button 
                    key={index}
                    className={`carousel-dot ${currentSlide === index ? 'active' : ''}`}
                    onClick={() => goToSlide(index)}
                  ></button>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-grid">
            {faqs.map((faq) => (
              <div key={faq.id} className="faq-item">
                <div className="faq-question">
                  <FaQuestionCircle />
                  <h3>{faq.question}</h3>
                </div>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </section>

        <div className="map-section">
          <div className="map-container">
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17695.953691183702!2d36.6715602356772!3d-3.3636012214400357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x18371d3570b6c277%3A0x6cf32424a5115d7f!2sTanzania%20Camping%20Safaris!5e1!3m2!1sen!2snl!4v1736645073322!5m2!1sen!2snl"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Office Location"
            ></iframe>
          </div>
          
          <div className="map-overlay">
            <h2>Find Us Here</h2>
            <p>Visit our office in Arusha, Tanzania</p>
            
            <div className="directions-info">
              <div className="direction-item">
                <h3>From Kilimanjaro International Airport (JRO)</h3>
                <ul>
                  <li>Distance: ~45 km (45-60 minutes drive)</li>
                  <li>Take Arusha-Moshi Highway towards Arusha</li>
                  <li>Enter Arusha city via Moshi Road</li>
                  <li>Turn right at Levolosi Junction</li>
                  <li>Our office is located at Plot 18F</li>
                </ul>
              </div>
              
              <div className="direction-item">
                <h3>From Arusha Airport (ARK)</h3>
                <ul>
                  <li>Distance: ~8 km (15-20 minutes drive)</li>
                  <li>Head north on Sokoine Road</li>
                  <li>Continue to Levolosi area</li>
                  <li>Office is visible from main road</li>
                </ul>
              </div>

              <div className="transport-options">
                <h3>Transport Options</h3>
                <ul>
                  <li>Airport Shuttle Service (pre-book)</li>
                  <li>Local Taxi Services</li>
                  <li>Uber/Bolt available</li>
                </ul>
              </div>

              <div className="landmark-info">
                <h3>Notable Landmarks</h3>
                <p>Near Levolosi Secondary School</p>
                <p>5 minutes from Clock Tower</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const quickServices = [
  {
    id: 1,
    icon: <FaPlane />,
    title: "Travel Planning",
    description: "Complete travel arrangements including flights, transfers, and accommodations"
  },
  {
    id: 2,
    icon: <FaHotel />,
    title: "Accommodation",
    description: "Luxury lodges and tented camps across Tanzania"
  },
  {
    id: 3,
    icon: <FaWhatsapp />,
    title: "24/7 Support",
    description: "Round-the-clock assistance throughout your journey"
  }
];

const faqs = [
  {
    id: 1,
    question: "What is the best time to visit Tanzania?",
    answer: "The best time depends on your interests. June to October is ideal for wildlife viewing, while December to March is great for bird watching and witnessing the wildebeest calving season."
  },
  {
    id: 2,
    question: "How do I book a safari?",
    answer: "You can book by contacting us via email, phone, or WhatsApp. We'll help you choose the perfect itinerary and handle all arrangements."
  },
  {
    id: 3,
    question: "What should I pack for a safari?",
    answer: "Essential items include comfortable clothing in neutral colors, sun protection, camera, binoculars, and any personal medications."
  },
  {
    id: 4,
    question: "Are your safaris suitable for families?",
    answer: "Yes, we offer family-friendly safaris with accommodations and activities suitable for all ages."
  }
];

export default ContactUs; 