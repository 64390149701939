import React from 'react';
import { Link } from 'react-router-dom';
import { FaCrown, FaHeart, FaTrophy } from 'react-icons/fa';
import './Cultural.css';
import SEO from '../components/SEO';
import { title } from 'process';

const Cultural = () => {
  return (
    <>
      <SEO 
        title="Cultural Tours"
        description="Discover the heart of Tanzania's culture with our authentic cultural tours and experiences."
        canonicalUrl="https://tanzaniacampingsafari.com/cultural"
      />
      <div className="cultural">
        <div className="hero-banner">
          <div className="hero-content">
            <h1>Discover Cultural Treasures</h1>
            <p>Discover hidden gems of history, art, and tradition. Our guided tours take you to the heart of unique cultures, revealing timeless treasures and unforgettable experiences.</p>
          </div>
        </div>

        <div className="cultural-intro">
          <h2>Discover the Heart of Tanzania's Culture</h2>
          <p>Tanzania is a land of rich heritage, diverse traditions, and vibrant communities. Our Tanzania Cultural Tours offer a unique chance to connect with the country's history, customs, and people. From bustling markets to serene villages, each experience provides a deep dive into the soul of this captivating country.</p>
        </div>

        <div className="maasai-gallery">
          {maasaiGallery.map((item, index) => (
            <div key={index} className="maasai-item">
              <img src={item.image} alt={item.title} />
              <div className="maasai-info">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="cultural-heritage">
          <h2>Cultural Heritage</h2>
          <div className="heritage-grid">
            <div className="heritage-card">
              <FaCrown className="heritage-icon" />
              <h3>Cultural Immersion</h3>
              <p>Experience authentic Maasai culture through village visits, traditional dances, and engaging with local communities.</p>
            </div>
            <div className="heritage-card">
              <FaHeart className="heritage-icon" />
              <h3>Local Traditions</h3>
              <p>Participate in traditional ceremonies, learn about medicinal plants, and discover indigenous practices.</p>
            </div>
            <div className="heritage-card">
              <FaTrophy className="heritage-icon" />
              <h3>Community Impact</h3>
              <p>Support local communities and community projects through responsible tourism practices that positively impact local initiatives.</p>
            </div>
          </div>
        </div>

        <div className="popular-experiences">
          <h2>Popular Cultural Tour Experiences</h2>
          <div className="experiences-container">
            {culturalExperiences.map((experience, index) => (
              <div key={index} className="experience-card">
                <div className="experience-image">
                  <img src={experience.image} alt={experience.title} />
                </div>
                <div className="experience-content">
                  <h3>{experience.title}</h3>
                  <p>{experience.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const maasaiGallery = [
  {
    title: "Hadzabe Tribe",
    image: "/images/culturalTours/HadzabeTribeexperience.jpg",
    description: "last remaining hunter-gatherer tribes in Africa"
  },
  {
    title: "Maasai Warriors",
    image: "/images/culturalTours/maasaiWorrior.jpg",
    description: "Maasai warriors performing traditional jumps"
  },
  {
    title: "Swahili Coast and Zanzibar",
    image: "/images/culturalTours/swahili womens.jpeg",
    description: "Visitors learning about Maasai traditions"
  },
  {
    title: "Sukuma Culture",
    image: "/images/culturalTours/sukuma.jpeg",
    description: "Authentic village life experiences"
  }
];

const culturalExperiences = [
  {
    title: "Maasai Village Visits",
    description: "Step into the world of the Maasai, one of Tanzania's most iconic tribes. Experience the ancient rituals, dances, and ceremonies that have been passed down through generations. Learn about their traditional lifestyle, colorful adornments, and their unique way of life that has survived for centuries.",
    image: "/images/culturalTours/tanzania-cultural-experience-6.jpg"
  },
  {
    title: "Swahili Coast and Zanzibar Cultural Experience",
    description: "Explore the island of Zanzibar, a place where African, Arab, and European cultures have blended for centuries. Walk historical sites and Stone Town, where narrow alleys and ancient buildings tell stories of cultural exchange. Discover Swahili cuisine, arts, and music that have influenced the region for centuries.",
    image: "/images/culturalTours/zanzibarculture.jpeg"
  },
  {
    title: "Hadzabe Tribe Experience",
    description: "The Hadzabe are one of the last remaining hunter-gatherer tribes in Africa. This rare and authentic experience offers insight into their ancient way of life. Learn how hunting and gathering skills are passed down through oral traditions. Participate in their hunting expeditions and learn about their daily rituals.",
    image: "/images/culturalTours/HadzabeTribeexperience.jpg"
  },
  {
    title: "Sukuma Village Tour",
    description: "The Sukuma people, known for their agricultural practices and rich cultural heritage, will give you a warm welcome in their communities. Experience their traditional homes, fascinating dances, and learn about their history and life in the rural heart of Tanzania.",
    image: "/images/culturalTours/BujoraVisit.jpg"
  },
  {
    title: "Ngorongoro Crater Cultural Heritage Exploration",
    description: "The Ngorongoro Conservation Area, a UNESCO World Heritage site for its biodiversity but also for its rich cultural heritage. Learn how local communities live in harmony with this stunning landscape for centuries. Visit their villages and understand East Africa's harmonious blend of culture and nature.",
    image: "https://img.freepik.com/premium-photo/maasai-dance-front-tourists-amboseli-national-park-kenya_621486-2500.jpg"
  }
];

export default Cultural; 