import React from 'react';
import { FaHandshake, FaExternalLinkAlt } from 'react-icons/fa';
import './PartnersCarousel.css';

// Main partner data
const mainPartner = {
  name: 'Gofan Safaris & Travel Africa',
  logo: 'https://www.gofansafaris.com/images/site/logo.png', // Use your main logo here
  url: 'https://www.gofansafaris.com',
  description: 'Your premier African safari partner offering luxury, midrange and budget safaris across East Africa. We specialize in wildlife safaris, mountain climbing, beach holidays and cultural tours.',
  type: 'Main Partner'
};

// Other partners data
const partners = [
  {
    name: 'Tripadvisor',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/tripadvisor_113_105.jpg',
    url: 'https://www.tripadvisor.com'
  },
  {
    name: 'Gofan African Safari',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_gofan_africa_239_129.jpg',
    url: 'https://www.gofansafaris.com'
  },
  {
    name: 'MagicalKenya The Official Kenya Destination',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_magicalkenya_249_180.jpg',
    url: 'https://www.magicalkenya.com'
  },
  {
    name: 'Kenya Association of Tour Operators (KATO)',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_kato_240_180.png',
    url: 'https://www.katokenya.org'
  },
  {
    name: 'Tanzania Tourist Board (TTB)',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_ttb_139_100.png',
    url: 'https://www.tanzaniatourism.go.tz'
  },
  {
    name: 'Tanzania National Parks (TANAPA)',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_tanapa_219_180.png',
    url: 'https://www.tanzaniaparks.go.tz'
  },
  {
    name: 'Zanzibar Commission for Tourism',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/zct_158_146.png',
    url: 'https://www.zanzibartourism.go.tz'
  },
  {
    name: 'Tanzania Government Official Website',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_tanzania_219_180.jpg',
    url: 'https://www.tanzania.go.tz'
  },
  {
    name: 'Tanzania Investiments Centre (TIC)',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_tic_188_180.png',
    url: 'https://www.tic.go.tz'
  },
  {
    name: 'Africa Travel Association (ATA)',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_ata_127_100.png',
    url: 'https://www.africatravelassociation.org'
  },
  {
    name: 'SafariBookings: African Safari Holidays',
    logo: 'https://www.gofansafaris.com/images/made/b08fbd80a4c01110/logo_safaribookings_265_97.png',
    url: 'https://www.safaribookings.com'
  }
];

const PartnersCarousel = () => {
  return (
    <div className="partners-section">
      <div className="section-header">
        <FaHandshake className="section-icon" />
        <h2>Our Partnerships</h2>
      </div>
      
      <p className="section-description">
        We are proud to partner with leading organizations and travel platforms throughout East Africa and beyond. 
        These strategic alliances allow us to provide our clients with the best possible safari experiences, 
        ensuring quality, authenticity, and adherence to industry standards.
      </p>
      
      {/* Main Partner Showcase */}
      <div className="main-partner-container">
        <div className="main-partner-card">
          <div className="main-partner-logo">
            <img src={mainPartner.logo} alt={mainPartner.name} onError={(e)=>{e.target.onerror = null; e.target.src="/images/logo.png"}} />
          </div>
          <div className="main-partner-content">
            <div className="main-partner-header">
              <h3>{mainPartner.name}</h3>
              <span className="partner-type featured">{mainPartner.type}</span>
            </div>
            <p>{mainPartner.description}</p>
            <a 
              href={mainPartner.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="partner-link featured"
            >
              Visit Our Main Website <FaExternalLinkAlt />
            </a>
          </div>
        </div>
      </div>
      
      <h3 className="partners-subheading">Our Valued Partners</h3>
      
      {/* Partners Carousel */}
      <div className="partners-carousel-container">
        <div className="partners-carousel">
          {partners.map((partner, index) => (
            <div key={index} className="partner-card-wrapper">
              <a 
                href={partner.url} 
                className="partner-card" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={partner.logo} alt={partner.name} onError={(e)=>{e.target.onerror = null; e.target.src="/images/logo-placeholder.png"}} />
              </a>
              <div className="partner-name">{partner.name}</div>
            </div>
          ))}
          {/* Duplicate partners for infinite scroll effect */}
          {partners.slice(0, 5).map((partner, index) => (
            <div key={`duplicate-${index}`} className="partner-card-wrapper">
              <a 
                href={partner.url} 
                className="partner-card" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={partner.logo} alt={partner.name} onError={(e)=>{e.target.onerror = null; e.target.src="/images/logo-placeholder.png"}} />
              </a>
              <div className="partner-name">{partner.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnersCarousel; 