import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './FAQs.css';
import SEO from '../components/SEO';
import { FaSearch, FaAngleDown, FaAngleUp, FaChevronRight, FaStar, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';

const categories = [
  'All',
  'Safari Planning',
  'Accommodation',
  'Transportation',
  'Wildlife & Parks',
  'Health & Safety',
  'Booking & Payment',
  'Tanzania Tourism',
  'Self Drive'
];

const faqData = {
  'Safari Planning': [
    {
      question: 'What is the best time to visit Tanzania for a safari?',
      answer: 'The best time for wildlife viewing is during the dry seasons (June to October and January to February). However, each season offers unique experiences. The wildebeest migration in the Serengeti occurs year-round but peaks in different areas at different times.'
    },
    {
      question: 'How long should I plan for a safari?',
      answer: 'We recommend a minimum of 5-7 days for a satisfying safari experience. This allows you to visit multiple parks and increases your chances of seeing diverse wildlife. Longer stays of 10-14 days enable you to combine safari with other experiences like beach time in Zanzibar.'
    },
    {
      question: 'Which parks should I visit?',
      answer: 'Popular circuits include the Northern Circuit (Serengeti, Ngorongoro, Tarangire) and the Southern Circuit (Ruaha, Selous). Your choice depends on your interests, time, and budget. We can help you plan the perfect itinerary based on your preferences.'
    },
    {
      question: 'What is the best way to see the Great Migration?',
      answer: 'The Great Migration moves in a clockwise circle through the Serengeti ecosystem year-round. For river crossings, visit Northern Serengeti (July-October). For calving season, visit Southern Serengeti (January-March). Our guides track the migration\'s exact location and position you for optimal viewing.'
    },
    {
      question: 'Can I customize my safari itinerary?',
      answer: 'Absolutely! Tanzania Camping and Self Drive specializes in customized safari experiences. We work with you to create an itinerary that matches your interests, timeframe, and budget. Whether you want to focus on photography, specific wildlife, or cultural experiences, we can tailor every aspect of your safari.'
    },
    {
      question: 'Is Tanzania suitable for family safaris with children?',
      answer: 'Yes, Tanzania is an excellent destination for family safaris. We offer family-friendly accommodations and can adjust game drives to suit children\'s attention spans. Many lodges have special activities for kids, and seeing wildlife in their natural habitat is an educational experience children will never forget. We recommend Tanzania for children aged 6 and above.'
    }
  ],
  'Accommodation': [
    {
      question: 'What types of accommodation are available?',
      answer: 'We offer various options from luxury lodges to tented camps. Lodges provide hotel-like amenities, while tented camps offer a more authentic safari experience. All accommodations are carefully selected for comfort, safety, and proximity to wildlife areas.'
    },
    {
      question: 'Are the tented camps comfortable?',
      answer: 'Yes, our tented camps provide comfortable beds, en-suite bathrooms, and quality amenities. They combine authentic safari atmosphere with modern comforts. Each tent is spacious and well-furnished.'
    },
    {
      question: 'Do all accommodations have electricity?',
      answer: 'Most lodges and permanent tented camps have electricity, though some run on generators with limited hours (typically morning and evening). Many camps use solar power for lighting and charging electronic devices. We\'ll inform you about the specific facilities at each accommodation in your itinerary.'
    },
    {
      question: 'Is Wi-Fi available at safari accommodations?',
      answer: 'Wi-Fi availability varies. Most luxury lodges offer Wi-Fi in common areas, while more remote camps may have limited or no connectivity. We recommend embracing the disconnected experience as part of your safari adventure, but we can prioritize accommodations with connectivity if required.'
    },
    {
      question: 'What\'s the difference between luxury and mid-range accommodations?',
      answer: 'Luxury accommodations offer premium amenities such as swimming pools, spa services, gourmet dining, spacious rooms with premium furnishings, and often exceptional locations with panoramic views. Mid-range options provide comfortable, clean accommodations with good service but fewer premium amenities. Both categories offer authentic safari experiences with excellent wildlife viewing opportunities.'
    }
  ],
  'Transportation': [
    {
      question: 'What type of vehicles do you use?',
      answer: 'We use well-maintained 4x4 safari vehicles (Toyota Land Cruisers or similar) with pop-up roofs for game viewing. All vehicles have comfortable seating, charging points, and are equipped with first-aid kits and communication devices.'
    },
    {
      question: 'How many people share a safari vehicle?',
      answer: 'Our vehicles typically accommodate 4-6 guests, ensuring everyone has a window seat and access to the pop-up roof for optimal game viewing. Private vehicles can be arranged upon request.'
    },
    {
      question: 'Are internal flights necessary for my safari?',
      answer: 'This depends on your itinerary. For safaris combining distant parks (like Serengeti and Selous) or including Zanzibar, internal flights save valuable time. For northern circuit safaris, road transfers between parks offer additional game viewing opportunities. We can advise on the most efficient transportation based on your specific itinerary.'
    },
    {
      question: 'How long are the drives between parks?',
      answer: 'Drive times vary: Arusha to Tarangire (2-3 hours), Tarangire to Ngorongoro (3-4 hours), Ngorongoro to Serengeti (3-5 hours depending on which section). These drives offer beautiful scenery and wildlife viewing opportunities. Our vehicles are comfortable with regular stops for photography, wildlife viewing, and refreshments.'
    },
    {
      question: 'Do you offer meet-and-greet services at airports?',
      answer: 'Yes, all our safari packages include airport transfers. Our representative will meet you at Kilimanjaro International Airport (JRO), Arusha Airport (ARK), or any other arrival point in Tanzania, holding a clear sign with your name. They will assist with luggage and transport you to your accommodation, providing a brief orientation along the way.'
    }
  ],
  'Wildlife & Parks': [
    {
      question: 'What wildlife can I expect to see on safari?',
      answer: 'Tanzania is home to incredible biodiversity. You can expect to see elephants, lions, giraffes, zebras, buffalo, and numerous antelope species across most parks. Leopards, cheetahs, and rhinos are less common but frequently spotted. The Serengeti and Ngorongoro are known for big cats, while Tarangire is famous for elephants. Each park offers unique wildlife viewing opportunities.'
    },
    {
      question: 'Can you guarantee the Big Five?',
      answer: 'While Tanzania is one of the best places to see the Big Five (lion, leopard, elephant, buffalo, and rhino), wildlife sightings are never guaranteed. Our experienced guides know the best locations and times to find these animals, but nature is unpredictable. The Ngorongoro Crater and Serengeti offer the best chances of seeing all five during a safari of 5+ days.'
    },
    {
      question: 'What makes each Tanzania national park unique?',
      answer: 'Each park has distinct features: Serengeti is known for vast plains and the wildebeest migration; Ngorongoro offers concentrated wildlife in a volcanic crater; Tarangire features baobab trees and large elephant herds; Lake Manyara has tree-climbing lions and flamingos; Ruaha and Selous in southern Tanzania offer more remote, less-visited wilderness experiences. We can help choose the right parks for your specific interests.'
    },
    {
      question: 'When and where can I see the wildebeest migration?',
      answer: 'The migration moves through different parts of the Serengeti ecosystem year-round: December-March (Southern Serengeti) for calving season; April-June (Central/Western Serengeti) as herds move northwest; July-October (Northern Serengeti) for dramatic river crossings; November-December as herds return south. We track the migration\'s current location to position you in the right place.'
    },
    {
      question: 'Are night game drives allowed in Tanzania?',
      answer: 'Night game drives are permitted in select areas, including some private concessions adjacent to national parks, Tarangire National Park, and the Ngorongoro Conservation Area (outside the crater). Night drives offer opportunities to see nocturnal animals like bush babies, civets, genets, and nighttime predator activity. We can incorporate night drives into your itinerary where permitted.'
    }
  ],
  'Health & Safety': [
    {
      question: 'What vaccinations do I need?',
      answer: 'Yellow fever vaccination is required if arriving from certain countries. We recommend consulting your doctor about other vaccinations and malaria prophylaxis. Travel insurance with medical coverage is mandatory for all our safaris.'
    },
    {
      question: 'Is it safe to go on safari?',
      answer: 'Yes, safari tourism in Tanzania is very safe. Our experienced guides are trained in safety protocols, and all activities follow strict guidelines. We maintain constant communication with our camps and lodges, and vehicles are equipped with safety equipment.'
    },
    {
      question: 'What medical facilities are available during safari?',
      answer: 'Major towns like Arusha have private hospitals capable of handling most emergencies. Remote safari locations may be several hours from advanced medical facilities. All our guides have first aid training, and we maintain communication with emergency services. We recommend comprehensive travel insurance that includes medical evacuation coverage for peace of mind.'
    },
    {
      question: 'Is malaria a concern in Tanzania?',
      answer: 'Malaria is present in most of Tanzania, including safari areas. We recommend consulting your doctor about appropriate prophylaxis before travel. In addition, we advise using insect repellent, wearing long sleeves/pants in evenings, and using the mosquito nets provided at accommodations. Higher altitude areas like Ngorongoro Crater rim have lower mosquito presence.'
    },
    {
      question: 'What about food and water safety?',
      answer: 'All accommodations we use maintain high food safety standards. Lodges and camps serve purified or bottled water, and we provide bottled water during game drives. We can accommodate special dietary requirements with advance notice. We recommend avoiding raw foods and using bottled water for drinking and brushing teeth.'
    }
  ],
  'Booking & Payment': [
    {
      question: 'How far in advance should I book?',
      answer: 'We recommend booking 6-12 months in advance, especially for peak season (July-September) and if you have specific lodges in mind. Last-minute bookings are possible but may have limited options.'
    },
    {
      question: 'What payment methods do you accept?',
      answer: 'We accept bank transfers, credit cards, and other secure payment methods. A 30% deposit is required to confirm your booking, with the balance due 60 days before arrival.'
    },
    {
      question: 'What is your cancellation policy?',
      answer: 'Our standard cancellation policy provides full refund (minus administrative fee) for cancellations 60+ days before arrival. Different terms apply for cancellations closer to the arrival date. We strongly recommend travel insurance.'
    },
    {
      question: 'Is travel insurance necessary?',
      answer: 'Yes, comprehensive travel insurance is mandatory for all our safaris. Your policy should cover trip cancellation, medical emergencies, evacuation, and lost luggage. We recommend purchasing insurance that includes COVID-19 coverage. We can suggest reputable providers if needed.'
    },
    {
      question: 'Are there any hidden costs I should be aware of?',
      answer: 'Our safari packages are designed to be transparent and inclusive. Prices typically include accommodation, meals, park fees, guide services, and transportation. Common exclusions are international flights, visa fees, travel insurance, gratuities, and personal expenses. We provide a detailed breakdown of what is and isn\'t included with every quote.'
    },
    {
      question: 'What is an appropriate amount to tip guides and staff?',
      answer: 'Tipping is customary in Tanzania\'s tourism industry. For safari guides, $15-25 per day per group is recommended. For camp/lodge staff, $5-10 per day per group is appropriate, typically placed in a communal tip box. For porters (e.g., at airports or for mountain climbing), $1-2 per bag is standard. These are guidelines - tipping remains at your discretion based on the service received.'
    }
  ],
  'Tanzania Tourism': [
    {
      question: 'What makes Tanzania a unique tourism destination compared to other African countries?',
      answer: 'Tanzania stands out as a premier tourism destination thanks to its unmatched biodiversity, seven UNESCO World Heritage Sites, Africa\'s highest mountain (Kilimanjaro), the world\'s largest intact caldera (Ngorongoro), the annual wildebeest migration, and pristine beaches of Zanzibar. Tanzania also offers authentic cultural tourism experiences with over 120 ethnic groups, making it a complete African destination.'
    },
    {
      question: 'What are the most popular tourism attractions in Tanzania?',
      answer: 'Tanzania is home to world-renowned tourism destinations including the Serengeti National Park, Ngorongoro Conservation Area, Mount Kilimanjaro, Zanzibar beaches, Tarangire National Park, and Lake Manyara. The annual wildebeest migration in the Serengeti is considered one of the greatest wildlife spectacles on earth and draws thousands of tourists annually.'
    },
    {
      question: 'When is the best time for Tanzania tourism and safari adventures?',
      answer: 'The best time for Tanzania tourism depends on your interests. The dry season (June-October) offers prime wildlife viewing with animals gathering around water sources. For witnessing the Serengeti wildebeest migration, December-July is ideal. Bird watching is excellent during the green season (November-May). Tanzania Camping and Self Drive offers year-round tours tailored to maximize your experience regardless of when you visit.'
    },
    {
      question: 'What types of tourism experiences does Tanzania Camping and Self Drive offer?',
      answer: 'Tanzania Camping and Self Drive specializes in diverse tourism experiences including luxury camping safaris, self-drive adventures, wildlife photography tours, Kilimanjaro climbing expeditions, cultural tourism packages, beach holidays in Zanzibar, honeymoon safaris, and family-friendly Tanzania tours. Each experience is customizable and led by expert local guides to ensure authentic and memorable Tanzania tourism adventures.'
    },
    {
      question: 'How much should I budget for a Tanzania tourism adventure?',
      answer: 'Tanzania tourism packages vary in price depending on duration, accommodation level, and experiences included. Budget safaris start around $200 per day, mid-range options around $350-500 per day, and luxury experiences from $600+ per day. Tanzania Camping and Self Drive offers options for all budgets while maintaining excellent quality, with most popular 5-7 day packages ranging from $1,500-$3,500 per person excluding international flights.'
    },
    {
      question: 'Do I need a visa to visit Tanzania?',
      answer: 'Most visitors to Tanzania require a visa. Many nationalities can obtain a tourist visa on arrival at major entry points or apply online through Tanzania\'s e-visa system before travel. The current cost is approximately $50-100 USD depending on nationality and visa type. We recommend checking the latest requirements with your nearest Tanzanian embassy or on the official Tanzania Immigration website.'
    },
    {
      question: 'What cultural experiences are available in Tanzania?',
      answer: 'Tanzania offers rich cultural tourism opportunities including Maasai village visits, guided walks with Hadzabe bushmen, traditional music and dance performances, local craft workshops, and tours of historical sites like Stone Town in Zanzibar (a UNESCO World Heritage site). We integrate authentic, respectful cultural experiences into our safari itineraries, supporting local communities through sustainable tourism initiatives.'
    }
  ],
  'Self Drive': [
    {
      question: 'Is it possible to self-drive in Tanzania\'s national parks?',
      answer: 'Yes, self-driving is permitted in Tanzania\'s national parks, and Tanzania Camping and Self Drive specializes in facilitating these adventures. We provide well-maintained 4x4 vehicles equipped with necessary safari features, detailed maps, GPS navigation systems, and emergency communication devices. Our self-drive packages include comprehensive briefings and 24/7 support.'
    },
    {
      question: 'Do I need special driving skills for a self-drive safari?',
      answer: 'Experience with 4x4 vehicles is beneficial but not mandatory. Our thorough orientation covers all aspects of driving in Tanzania, including how to handle different terrain. The most important requirements are confidence behind the wheel, adaptability, and a sense of adventure. We assess each client\'s driving experience to determine suitability for different self-drive routes.'
    },
    {
      question: 'How does navigation work on a self-drive safari?',
      answer: 'We provide multiple navigation tools: GPS devices pre-loaded with your route and points of interest, physical maps marked with your itinerary, and a mobile app with offline maps. Before departure, we conduct a detailed route briefing. Additionally, we maintain regular check-ins via satellite phone or mobile networks where available.'
    },
    {
      question: 'What happens if there\'s a vehicle breakdown or emergency?',
      answer: 'All our vehicles undergo rigorous maintenance before each trip. In case of breakdown, we provide 24/7 emergency support via satellite phone and have a network of mechanics throughout Tanzania. For serious emergencies, we coordinate evacuation procedures. Comprehensive vehicle insurance is included, and we provide training on basic troubleshooting like changing tires.'
    },
    {
      question: 'Can we combine self-drive with guided experiences?',
      answer: 'Absolutely! Many clients opt for hybrid itineraries, self-driving between destinations then joining local guides for specialized experiences like walking safaris, night drives, or cultural visits. This combination offers both independence and expert insight. We can arrange for guides to meet you at specific locations or accompany you for portions of your journey.'
    }
  ]
};

const popularQuestions = [
  {
    question: "When is the best time to see the wildebeest migration?",
    category: "Safari Planning"
  },
  {
    question: "What makes Tanzania Camping and Self Drive unique?",
    category: "Tanzania Tourism"
  },
  {
    question: "Is it safe to self-drive in Tanzania's national parks?",
    category: "Self Drive"
  },
  {
    question: "What wildlife can I expect to see on safari?",
    category: "Wildlife & Parks"
  }
];

const FAQs = () => {
  const [activeCategory, setActiveCategory] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');
  const [openItems, setOpenItems] = useState({});
  const [popularQuestion, setPopularQuestion] = useState(null);

  const heroImage = "https://images.unsplash.com/photo-1523805009345-7448845a9e53?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2072&q=80";

  useEffect(() => {
    // Show a random popular question
    const randomIndex = Math.floor(Math.random() * popularQuestions.length);
    setPopularQuestion(popularQuestions[randomIndex]);
  }, []);

  const createFAQSchema = () => {
    const mainEntity = [];
    
    Object.entries(faqData).forEach(([category, items]) => {
      items.forEach(item => {
        mainEntity.push({
          "@type": "Question",
          "name": item.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": item.answer
          }
        });
      });
    });
    
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": mainEntity
    };
  };

  const toggleItem = (category, index) => {
    setOpenItems(prev => ({
      ...prev,
      [`${category}-${index}`]: !prev[`${category}-${index}`]
    }));
  };

  const filterFAQs = () => {
    if (activeCategory === 'All' && !searchQuery) {
      return faqData;
    }

    let filtered = {};
    Object.entries(faqData).forEach(([category, items]) => {
      if (activeCategory === 'All' || activeCategory === category) {
        const filteredItems = items.filter(
          item =>
            item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.answer.toLowerCase().includes(searchQuery.toLowerCase())
        );
        if (filteredItems.length > 0) {
          filtered[category] = filteredItems;
        }
      }
    });
    return filtered;
  };

  // Calculate total questions for each category
  const categoryQuestionCount = Object.entries(faqData).reduce((acc, [category, items]) => {
    acc[category] = items.length;
    return acc;
  }, {});

  // Calculate total questions
  const totalQuestions = Object.values(categoryQuestionCount).reduce((sum, count) => sum + count, 0);

  return (
    <div className="faqs-container" style={{
      '--hero-bg': `url(${heroImage})`
    }}>
      <SEO 
        title="Tanzania Safari and Tourism FAQs | Expert Answers to Your Questions"
        description="Get expert answers about Tanzania tourism, safari tours, wildlife viewing, best seasons to visit, and why Tanzania Camping and Self Drive offers the best safari experiences in East Africa."
        keywords="Tanzania tourism FAQs, Tanzania safari questions, best time to visit Tanzania, self-drive safari Tanzania, Tanzania wildlife parks, sustainable tourism Tanzania, Tanzania vacation planning, Tanzania travel tips, Tanzania accommodation options, Tanzania safari costs"
        schema={createFAQSchema()}
        canonicalUrl="https://tanzaniacampingsafaris.com/faqs"
      />

      <div className="faqs-hero">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to common questions about Tanzania safaris and tourism</p>
        <div className="faq-stats">
          <div className="faq-stat-item">
            <span className="faq-stat-number">{totalQuestions}+</span>
            <span className="faq-stat-label">Questions Answered</span>
          </div>
          <div className="faq-stat-item">
            <span className="faq-stat-number">{Object.keys(faqData).length}</span>
            <span className="faq-stat-label">Categories</span>
          </div>
        </div>
      </div>

      {popularQuestion && (
        <div className="popular-question-banner">
          <div className="popular-question-content">
            <span className="popular-tag"><FaStar /> Popular Question</span>
            <h3>{popularQuestion.question}</h3>
            <Link to={`#${popularQuestion.category}`} className="view-answer-btn">
              View Answer <FaChevronRight />
            </Link>
          </div>
        </div>
      )}

      <div className="faqs-content">
        <div className="faqs-search">
          <div className="search-input-container">
            <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search FAQs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          </div>
        </div>

        <div className="faq-categories">
          {categories.map(category => (
            <button
              key={category}
              className={`category-button ${activeCategory === category ? 'active' : ''}`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
              {category !== 'All' && categoryQuestionCount[category] && (
                <span className="category-count">{categoryQuestionCount[category]}</span>
              )}
            </button>
          ))}
        </div>

        {searchQuery && Object.keys(filterFAQs()).length === 0 && (
          <div className="no-results">
            <h3>No questions found matching "{searchQuery}"</h3>
            <p>Try different keywords or browse our categories instead.</p>
          </div>
        )}

        {Object.entries(filterFAQs()).map(([category, items]) => (
          <div id={category} key={category} className="faq-section">
            <h2>{category}</h2>
            {items.map((item, index) => (
              <div key={index} className="faq-item">
                <div
                  className="faq-question"
                  onClick={() => toggleItem(category, index)}
                >
                  <h3>{item.question}</h3>
                  {openItems[`${category}-${index}`] ? 
                    <FaAngleUp className="toggle-icon" /> : 
                    <FaAngleDown className="toggle-icon" />
                  }
                </div>
                <div className={`faq-answer ${openItems[`${category}-${index}`] ? 'open' : ''}`}>
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        ))}

        <div className="contact-section">
          <h2>Still Have Questions?</h2>
          <p>Can't find the answer you're looking for? Our tourism experts are ready to help you plan your perfect Tanzania adventure.</p>
          
          <div className="contact-options">
            <Link to="/contact-us" className="contact-button primary">
              Contact Us
            </Link>
            <a href="tel:+255XXXXXXXX" className="contact-option">
              <FaPhoneAlt /> Call Us
            </a>
            <a href="mailto:info@tanzaniacampingsafaris.com" className="contact-option">
              <FaEnvelope /> Email Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs; 