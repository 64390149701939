import React from 'react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';
import './NotFound.css';

function NotFound() {
  return (
    <>
      <SEO 
        title="Page Not Found"
        description="The page you are looking for could not be found."
        canonicalUrl="https://tanzaniacampingsafari.com/404"
      />
      <div className="not-found-container">
        <div className="not-found-content">
          <img 
            src="/images/logo.png" 
            alt="Tanzania Camping Safaris Logo" 
            className="not-found-logo"
          />
          <h1>404 - Page Not Found</h1>
          <p className="not-found-message">
            Oops! It seems you've ventured off the trail.
            The page you're looking for might have moved or doesn't exist.
          </p>
          <div className="not-found-suggestions">
            <h2>You might want to:</h2>
            <ul>
              <li>Check the URL for typos</li>
              <li>Go back to our homepage</li>
              <li>Browse our popular safari packages</li>
              <li>Contact us for assistance</li>
            </ul>
          </div>
          <div className="not-found-buttons">
            <Link to="/" className="primary-button">
              Return to Homepage
            </Link>
            <Link to="/contact-us" className="secondary-button">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound; 