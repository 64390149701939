import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUsers, FaCampground, FaUtensils, FaMapMarkedAlt, FaCalendarAlt, FaStar, FaChevronRight, FaLeaf, FaMoon, FaSun, FaShieldAlt, FaCamera } from 'react-icons/fa';
import './CampingTours.css';
import SEO from '../components/SEO';

const CampingTours = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [animatedTours, setAnimatedTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);

  useEffect(() => {
    // Animate tours in sequence
    const timer = setTimeout(() => {
      setAnimatedTours(tours);
    }, 100);
    
    return () => clearTimeout(timer);
  }, []);

  const filterTours = (category) => {
    setActiveTab(category);
    setAnimatedTours([]);
    
    setTimeout(() => {
      if (category === 'all') {
        setAnimatedTours(tours);
      } else {
        setAnimatedTours(tours.filter(tour => tour.category === category));
      }
    }, 100);
  };

  return (
    <>
      <SEO 
        title="Camping Tours"
        description="Experience the authentic African wilderness with our luxury camping safaris in Tanzania. Immerse yourself in nature while enjoying comfortable accommodations and expert guidance."
        canonicalUrl="https://tanzaniacampingsafaris.com/camping-tours"
      />
      
      <div className="camping-page">
        <div className="camping-hero">
          <div className="hero-content">
            <h1>Tanzania Camping Safaris</h1>
            <p>Experience the magic of sleeping under African stars</p>
            <div className="hero-buttons">
              <button 
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('tours').scrollIntoView({ behavior: 'smooth' });
                }} 
                className="primary-btn"
              >
                Explore Tours
              </button>
              <button 
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById('experience').scrollIntoView({ behavior: 'smooth' });
                }} 
                className="secondary-btn"
              >
                The Experience
              </button>
            </div>
          </div>
          <div className="hero-overlay"></div>
        </div>

        <section className="camping-intro">
          <div className="container">
            <div className="intro-content">
              <h2>Authentic Wilderness Experience</h2>
              <p>Our camping safaris offer the perfect blend of adventure and comfort, allowing you to connect with Tanzania's breathtaking landscapes and wildlife in their natural habitat. From the vast plains of Serengeti to the magnificent Ngorongoro Crater, experience Africa as it was meant to be experienced - under canvas, with the sounds of the wild as your soundtrack.</p>
              
              <div className="intro-features">
                <div className="feature">
                  <div className="feature-icon">
                    <FaCampground />
                  </div>
                  <h3>Luxury Camping</h3>
                  <p>Comfortable tents with real beds and quality linens</p>
                </div>
                <div className="feature">
                  <div className="feature-icon">
                    <FaUtensils />
                  </div>
                  <h3>Gourmet Dining</h3>
                  <p>Fresh, locally-sourced meals prepared by skilled chefs</p>
                </div>
                <div className="feature">
                  <div className="feature-icon">
                    <FaMapMarkedAlt />
                  </div>
                  <h3>Expert Guides</h3>
                  <p>Knowledgeable local guides with years of experience</p>
                </div>
                <div className="feature">
                  <div className="feature-icon">
                    <FaShieldAlt />
                  </div>
                  <h3>Safety First</h3>
                  <p>Comprehensive safety protocols and equipment</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="experience" className="camping-experience">
          <div className="container">
            <h2 className="experience-title">The Camping Safari Experience</h2>
            <p className="experience-subtitle">Immerse yourself in the rhythm of nature with our authentic camping safaris</p>
            
            <div className="experience-cards">
              <div className="experience-card morning">
                <div className="card-icon">
                  <FaSun />
                </div>
                <h3>Morning</h3>
                <p>Wake up to the sounds of nature and enjoy a hot breakfast before heading out for an early game drive when wildlife is most active.</p>
                <img src="https://img.freepik.com/premium-photo/woman-drinking-coffee-morning-glamping-luxury-tent_1048944-11935608.jpg" alt="Morning coffee" />
              </div>
              
              <div className="experience-card morning">
                <div className="card-icon">
                  <FaCamera />
                </div>
                <h3>Daytime</h3>
                <p>Explore Tanzania's incredible landscapes and wildlife with expert guides. Enjoy picnic lunches in scenic locations.</p>
                <img src="https://img.freepik.com/premium-photo/rear-view-woman-looking-giraffe-through-car-roof_1048944-11935606.jpg" alt="Daytime safari" />
              </div>
              
              <div className="experience-card evening">
                <div className="card-icon">
                  <FaMoon />
                </div>
                <h3>Evening</h3>
                <p>Return to camp for sundowners around the campfire, followed by a delicious dinner under the stars and stories of the day's adventures.</p>
                <img src="https://img.freepik.com/premium-photo/ai-image-generator-bonfire-with-firewood-chairs-camping_977285-39288.jpg" alt="Evening campfire" />
              </div>
            </div>
          </div>
        </section>

        <section id="tours" className="camping-tours">
          <div className="container">
            <h2>Our Camping Safari Tours</h2>
            <p className="section-intro">Choose from our carefully crafted camping safaris, each offering unique wildlife experiences and landscapes</p>
            
            <div className="tour-categories">
              <button 
                className={activeTab === 'all' ? 'active' : ''} 
                onClick={() => filterTours('all')}
              >
                All Tours
              </button>
              <button 
                className={activeTab === 'luxury' ? 'active' : ''} 
                onClick={() => filterTours('luxury')}
              >
                Luxury Camping
              </button>
              <button 
                className={activeTab === 'budget' ? 'active' : ''} 
                onClick={() => filterTours('budget')}
              >
                Budget Friendly
              </button>
              <button 
                className={activeTab === 'family' ? 'active' : ''} 
                onClick={() => filterTours('family')}
              >
                Family Safaris
              </button>
            </div>
            
            <div className="tours-grid">
              {animatedTours.map((tour, index) => (
                <div key={tour.id} className="tour-card" style={{animationDelay: `${index * 0.1}s`}}>
                  <div className="tour-image">
                    <img src={tour.image} alt={tour.title} />
                    <div className="tour-badges">
                      <span className="tour-duration">
                        <FaCalendarAlt /> {tour.duration}
                      </span>
                      {tour.bestseller && (
                        <span className="tour-bestseller">
                          <FaStar /> Bestseller
                        </span>
                      )}
                    </div>
                  </div>
                  
                  <div className="tour-content">
                    <h3>{tour.title}</h3>
                    <div className="tour-highlights">
                      {tour.highlights.map((highlight, i) => (
                        <span key={i} className="highlight">
                          <FaLeaf /> {highlight}
                        </span>
                      ))}
                    </div>
                    <p>{tour.description}</p>
                    
                    <div className="tour-features">
                      {tour.features.map((feature, i) => (
                        <div key={i} className="tour-feature">
                          {feature.icon}
                          <span>{feature.text}</span>
                        </div>
                      ))}
                    </div>
                    
                    <div className="tour-footer">
                      <div className="tour-price">
                        <span className="price-from">From</span>
                        <span className="price-value">${tour.price}</span>
                        <span className="price-per">per person</span>
                      </div>
                      <button 
                        onClick={() => setSelectedTour(tour)} 
                        className="tour-button"
                      >
                        View Details <FaChevronRight />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="camping-testimonials">
          <div className="container">
            <h2>What Our Campers Say</h2>
            <div className="testimonials-grid">
              {testimonials.map(testimonial => (
                <div key={testimonial.id} className="testimonial-card">
                  <div className="testimonial-content">
                    <div className="quote-icon">"</div>
                    <p>{testimonial.text}</p>
                    <div className="testimonial-rating">
                      {[...Array(5)].map((_, i) => (
                        <FaStar key={i} className={i < testimonial.rating ? 'filled' : ''} />
                      ))}
                    </div>
                  </div>
                  <div className="testimonial-author">
                    <img src={testimonial.avatar} alt={testimonial.name} />
                    <div>
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.location}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="camping-cta">
          <div className="container">
            <div className="cta-content">
              <h2>Ready for Your Camping Adventure?</h2>
              <p>Let us help you plan the perfect Tanzania camping safari experience</p>
              <div className="cta-buttons">
                <Link to="/enquiry" className="cta-primary">Plan My Safari</Link>
                <Link to="/contact-us" className="cta-secondary">Contact Us</Link>
              </div>
            </div>
          </div>
        </section>
      </div>

      {selectedTour && (
        <div className="tour-modal-overlay" onClick={() => setSelectedTour(null)}>
          <div className="tour-modal" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setSelectedTour(null)}>×</button>
            
            <div className="modal-image">
              <img src={selectedTour.image} alt={selectedTour.title} />
              <div className="modal-badges">
                <span className="tour-duration">
                  <FaCalendarAlt /> {selectedTour.duration}
                </span>
                {selectedTour.bestseller && (
                  <span className="tour-bestseller">
                    <FaStar /> Bestseller
                  </span>
                )}
              </div>
            </div>
            
            <div className="modal-content">
              <h2>{selectedTour.title}</h2>
              
              <div className="modal-highlights">
                <h3>Tour Highlights</h3>
                <div className="highlights-grid">
                  {selectedTour.highlights.map((highlight, i) => (
                    <span key={i} className="highlight">
                      <FaLeaf /> {highlight}
                    </span>
                  ))}
                </div>
              </div>
              
              <div className="modal-description">
                <h3>Description</h3>
                <p>{selectedTour.description}</p>
              </div>
              
              <div className="modal-features">
                <h3>Tour Features</h3>
                <div className="features-grid">
                  {selectedTour.features.map((feature, i) => (
                    <div key={i} className="modal-feature">
                      {feature.icon}
                      <span>{feature.text}</span>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="modal-itinerary">
                <h3>Sample Itinerary</h3>
                <div className="itinerary-days">
                  {/* Sample itinerary - you can replace with actual data */}
                  <div className="itinerary-day">
                    <div className="day-number">Day 1</div>
                    <div className="day-content">
                      <h4>Arrival & Welcome</h4>
                      <p>Arrive at the camp, meet your guides, and enjoy a welcome dinner as you prepare for your adventure.</p>
                    </div>
                  </div>
                  <div className="itinerary-day">
                    <div className="day-number">Day 2</div>
                    <div className="day-content">
                      <h4>Morning Safari</h4>
                      <p>Early morning game drive to spot wildlife at their most active time, followed by a picnic breakfast in the bush.</p>
                    </div>
                  </div>
                  <div className="itinerary-day">
                    <div className="day-number">Day 3</div>
                    <div className="day-content">
                      <h4>Cultural Experience</h4>
                      <p>Visit a local Maasai village to learn about their traditions and way of life.</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="modal-footer">
                <div className="modal-price">
                  <span>From ${selectedTour.price}</span>
                  <span className="price-per">per person</span>
                </div>
                <Link 
                  to="/enquiry" 
                  state={{ 
                    tripName: selectedTour.title,
                    tripDetails: `Tour: ${selectedTour.title}
Duration: ${selectedTour.duration}
Price: $${selectedTour.price} per person
Category: ${selectedTour.category.charAt(0).toUpperCase() + selectedTour.category.slice(1)}
Highlights: ${selectedTour.highlights.join(', ')}
Description: ${selectedTour.description}`
                  }}
                  className="modal-book-btn"
                >
                  Book This Tour
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

  const tours = [
    {
      id: 1,
    title: "Serengeti Wilderness Explorer",
    image: "https://img.freepik.com/free-photo/tourist-watching-zebras-savanna_23-2149058076.jpg",
    duration: "5 Days",
    description: "Experience the wild beauty of Serengeti with luxury camping under the stars. Watch the great migration and enjoy authentic safari experiences with expert guides.",
    price: "1,299",
    category: "luxury",
    bestseller: true,
    highlights: ["Great Migration", "Big Five", "Sunset Views"],
      features: [
      { icon: <FaCampground />, text: "Luxury Tents" },
        { icon: <FaUsers />, text: "Max 6 People" },
      { icon: <FaUtensils />, text: "Gourmet Meals" },
      { icon: <FaMapMarkedAlt />, text: "Expert Guides" }
      ]
    },
    {
      id: 2,
      title: "Ngorongoro Crater Safari",
    image: "https://img.freepik.com/free-photo/crossroad-car-safari-scene_23-2151822336.jpg",
    duration: "4 Days",
    description: "Discover the eighth wonder of the world with our exclusive camping experience in the Ngorongoro Conservation Area, home to incredible wildlife density.",
    price: "1,099",
    category: "luxury",
    bestseller: false,
    highlights: ["Crater Floor", "Rhino Sightings", "Maasai Culture"],
      features: [
      { icon: <FaCampground />, text: "Premium Tents" },
        { icon: <FaUsers />, text: "Max 4 People" },
        { icon: <FaUtensils />, text: "Full Board" },
      { icon: <FaMapMarkedAlt />, text: "Cultural Visits" }
      ]
    },
    {
      id: 3,
      title: "Tarangire Wildlife Camp",
    image: "https://img.freepik.com/premium-photo/rear-view-woman-looking-giraffe-through-car-roof_1048944-11935606.jpg",
    duration: "3 Days",
    description: "Immerse yourself in the wilderness of Tarangire National Park with our comfortable camping facilities and expert guides. Famous for its elephant herds and baobab trees.",
    price: "899",
    category: "budget",
    bestseller: false,
    highlights: ["Elephant Herds", "Baobab Trees", "Bird Watching"],
      features: [
      { icon: <FaCampground />, text: "Comfortable Tents" },
        { icon: <FaUsers />, text: "Max 8 People" },
      { icon: <FaUtensils />, text: "Home-style Meals" },
        { icon: <FaMapMarkedAlt />, text: "Game Drives" }
      ]
  },
  {
    id: 4,
    title: "Family Safari Adventure",
    image: "https://img.freepik.com/free-photo/young-multiethnic-international-romantic-couple-outdoors-meadow-sunny-summer-day-african-american-man-caucasian-woman-having-picnic-together-concept-relationship-summertime_155003-23121.jpg",
    duration: "6 Days",
    description: "A perfect safari for families with children, featuring kid-friendly activities, educational wildlife experiences, and comfortable family tents.",
    price: "1,499",
    category: "family",
    bestseller: true,
    highlights: ["Kid-friendly", "Educational", "Safe Environment"],
    features: [
      { icon: <FaCampground />, text: "Family Tents" },
      { icon: <FaUsers />, text: "Max 10 People" },
      { icon: <FaUtensils />, text: "Child-friendly Meals" },
      { icon: <FaMapMarkedAlt />, text: "Junior Ranger Program" }
    ]
  },
  {
    id: 5,
    title: "Budget Serengeti Explorer",
    image: "https://img.freepik.com/premium-photo/ai-image-generator-bonfire-with-firewood-chairs-camping_977285-39288.jpg",
    duration: "4 Days",
    description: "Experience the magic of Serengeti on a budget without compromising on wildlife experiences. Perfect for adventurous travelers seeking value.",
    price: "799",
    category: "budget",
    bestseller: false,
    highlights: ["Affordable", "Wildlife Focus", "Small Groups"],
    features: [
      { icon: <FaCampground />, text: "Standard Tents" },
      { icon: <FaUsers />, text: "Max 8 People" },
      { icon: <FaUtensils />, text: "Hearty Meals" },
      { icon: <FaMapMarkedAlt />, text: "Shared Game Drives" }
    ]
  },
  {
    id: 6,
    title: "Lake Manyara & Tarangire Family Safari",
    image: "https://img.freepik.com/free-photo/beautiful-tropical-beach-sea-with-coconut-palm-tree_74190-7488.jpg",
    duration: "5 Days",
    description: "A perfect combination for families, exploring the tree-climbing lions of Lake Manyara and the elephant herds of Tarangire with child-friendly activities.",
    price: "1,299",
    category: "family",
    bestseller: false,
    highlights: ["Tree-climbing Lions", "Elephant Herds", "Swimming Pool"],
    features: [
      { icon: <FaCampground />, text: "Family Lodges" },
      { icon: <FaUsers />, text: "Private Groups" },
      { icon: <FaUtensils />, text: "Buffet Options" },
      { icon: <FaMapMarkedAlt />, text: "Short Drive Times" }
    ]
  }
];

const testimonials = [
  {
    id: 1,
    text: "The camping experience was beyond our expectations! Falling asleep to the sounds of lions roaring in the distance while staying in a comfortable tent was magical. Our guide was incredibly knowledgeable and made sure we saw all the Big Five.",
    name: "Sarah Johnson",
    location: "United States",
    avatar: "https://randomuser.me/api/portraits/women/32.jpg",
    rating: 5
  },
  {
    id: 2,
    text: "As a family with two young children, we were initially hesitant about a camping safari, but it turned out to be the highlight of our year! The staff was amazing with our kids, and the family tent was spacious and comfortable.",
    name: "David Williams",
    location: "United Kingdom",
    avatar: "https://randomuser.me/api/portraits/men/54.jpg",
    rating: 5
  },
  {
    id: 3,
    text: "The budget camping safari was perfect for us backpackers. We got to experience the incredible wildlife of Tanzania without breaking the bank. The food was delicious and plentiful, and our guide had eagle eyes for spotting animals!",
    name: "Emma Chen",
    location: "Australia",
    avatar: "https://randomuser.me/api/portraits/women/68.jpg",
    rating: 4
  }
];

export default CampingTours; 